<template>
  <div v-loading="registering">
    <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
        <div class="row no_border">
          <el-form-item :label="$t('Label.I/P, D/N')" prop="docType">
            <el-select v-model="searchForm.docType" placeholder="Select" clearable>
              <el-option
                v-for="docType in [{code: 'IP', label: 'I/P'},{code: 'DN', label: 'D/N'}]"
                :key="docType.code"
                :label="docType.label"
                :value="docType.code">
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item :label="$t('Label.Type')" prop="ipDnType">
            <el-select v-model="searchForm.ipDnType" placeholder="Select" clearableclass="section" :class="{multiple_selected: multipleSelect(searchForm.ipDnType)}" multiple collapse-tags clearable>
              <el-option
                v-for="ipDnType in IP_DN_TYPE_VARIABLES"
                :key="ipDnType.code"
                :label="ipDnType.label"
                :value="ipDnType.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
            <el-input type="text" v-model="searchForm.invoiceNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.O/P No')" prop="opOcProvNo">
            <el-input type="text" v-model="searchForm.opOcProvNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.I/P No')" prop="insurancePolicyNo">
            <el-input type="text" v-model="searchForm.insurancePolicyNo" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
        </div>
        <div class="row no_border">
          <el-form-item :label="$t('Label.Company')" prop="ownerCompanyName">
            <el-input type="text" v-model="searchForm.ownerCompanyName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Section')" prop="ownerSectionName">
            <el-input type="text" v-model="searchForm.ownerSectionName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Assured')" prop="assuredName">
            <el-input type="text" v-model="searchForm.assuredName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Vessel Name')" prop="vesselName">
            <el-input type="text" v-model="searchForm.vesselName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Sailing Date')" prop="sailingOnOrAbout">
            <el-date-picker
              v-model="searchForm.sailingOnOrAbout"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item :label="$t('Label.Insurance Company')" prop="insuranceCompanyName">
            <el-input type="text" v-model="searchForm.insuranceCompanyName" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Goods')" prop="descriptionOfGoods">
            <el-input type="text" v-model="searchForm.descriptionOfGoods" placeholder="Enter keyword" maxlength="300"></el-input>
          </el-form-item>
          <el-form-item :label="$t('Label.Registered Date')" prop="createDate">
            <el-date-picker
              v-model="searchForm.createDate"
              type="daterange"
              range-separator="to"
              format="yyyy-MM-dd"
              :default-value="defaultDate"
              @change.native="onDateRangeChange"
              start-placeholder="Start date"
              end-placeholder="End date">
            </el-date-picker>
          </el-form-item>
        </div>
        <div class="row no_border drop">
          <tw-button
            type="secondary"
            size="medium"
            icon="search"
            @click="search"
          >
            Search
          </tw-button>
          <tw-button
            type="default"
            size="medium"
            @click="clear"
            style="margin: 8px 16px 16px;"
          >
            Clear
          </tw-button>
        </div>
      </el-form>
    <div :class="{ is_loading: isLoading }" style="min-height:388px">
      <tw-table-system
        :height="300"
        :schemas="schemas"
        :items="items"
        :pageSize="limit"
        :totalCount="totalCount"
        :selection="true"
        :rowKey="getRowKey"
        :offset="offset"
        :serverPagination="true"
        @selection-change="selectionChange"
        @row-click="rowClick"
        @paging="onPaging"
        ref="table"
        mode="POOL"
      />
      <div class="row no_border drop register" style="margin-top:16px">
        <tw-button
          v-show="items && items.length"
          type="primary"
          size="medium"
          @click="register"
          :disabled="selectionItems && selectionItems.length === 0"
        >
          OK
        </tw-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { IP_DN_LINK_FLG, IP_DN_TYPE_VARIABLES, DELETE_FLG } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { clearSearchForm } from '@/utils/searchUtil.js';
import schemas from '@/dictionaries/ListIpDnPoolListSchema.json';
import dayjs from 'dayjs';

export default {
  name: 'TwAddIpDn',
  mixins: [mixinEntityList],
  components: {
    TwButton,
    TwTableSystem
  },
  props: {
    ipDn: Array
  },
  data() {
    return {
      items: null,
      limit: 20,
      searchForm: {
        docType: '', // 検索項目としてのドキュメントタイプ
        ipDnType: [],
        invoiceNo: '',
        opOcProvNo: '',
        insurancePolicyNo: '',
        ownerCompanyName: '',
        ownerSectionName: '',
        vesselName: '',
        sailingOnOrAbout: '',
        assuredName: '',
        insuranceCompanyName: '',
        descriptionOfGoods: '',
        createDate: '',
        includeDeletedData: false,
        linkingFlag: String(IP_DN_LINK_FLG.UNLINKED)
      },
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      currentPage: 1,
      maxNum: 10 ** 16, // 300桁
      selectionItems: [],
      isLoading: false,
      registering: false,
    };
  },
  computed: {
    schemas() {
      return _.reject(_.cloneDeep(schemas), table => {
        return table.key === 'tradingFlowId' || table.key === 'processName' || table.key === 'deleteFlg';
      });
    },
    IP_DN_TYPE_VARIABLES() {
      return _.reject(_.cloneDeep(IP_DN_TYPE_VARIABLES), type => {
        // IP,DN種別のcode値が2桁の区分値は検索用ではない（実データには登録されていない表示用区分値）のため除外
        return String(type.code).length > 1;
      });
    }
  },
  created() {
    const queries = this.$store.getters.getQueries('IP_DN_POOL_SELECT');
    if (queries) {
      this.searchForm = queries;
    } else {
      // 検索初期条件：Receive Data直近1ヶ月昇順
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0);
      this.searchForm.createDate = [today.subtract(1, 'month').format(), today.format()];
    }
    this.fetch();
  },
  methods: {
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      return {
        docType: f.docType,
        ipDnType: f.ipDnType && f.ipDnType.length ? [f.ipDnType] : null,
        invoiceNo: f.invoiceNo,
        opOcProvNo: f.opOcProvNo,
        insurancePolicyNo: f.insurancePolicyNo,
        ownerCompanyName: f.ownerCompanyName,
        ownerSectionName: f.ownerSectionName,
        vesselName: f.vesselName,
        sailingOnOrAboutFrom: f.sailingOnOrAbout && f.sailingOnOrAbout.length ? dayjs(this.getStartDate(f.sailingOnOrAbout)).format('YYYYMMDD') : null,
        sailingOnOrAboutTo: f.sailingOnOrAbout && f.sailingOnOrAbout.length ? dayjs(this.getEndDate(f.sailingOnOrAbout)).format('YYYYMMDD') : null,
        assuredName: f.assuredName,
        insuranceCompanyName: f.insuranceCompanyName,
        descriptionOfGoods: f.descriptionOfGoods,
        createDateFrom: f.createDate ? this.getStartDate(f.createDate) : null,
        createDateTo: f.createDate ? this.getEndDate(f.createDate) : null,
        includeDeletedData: f.includeDeletedData,
        linkingFlag: String(IP_DN_LINK_FLG.UNLINKED),
        limit: this.limit,
        offset: this.offset,
        sortType: this.sort ? this.sort.sortType : null,
        target: this.sort ? this.sort.target : null
      };
    },
    fetch() {
      this.isLoading = true;
      // BFF_IPP01 I/P,D/Nプール一覧検索BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/ip-pool-datas/search',
          query: this.transformQueries()
        }
      };

      $api
        .request(params)
        .then(res => {
          this.items = res.ipdnpools && res.ipdnpools.length ? this.setIndex(this.flatten(res.ipdnpools)) : [];
          // 選択中のデータには選択済みフラグを追加する
          if (this.ipDn && this.ipDn.length) {
            this.items = _.map(this.items, item => {
              if (
                _.some(this.ipDn, ipDn => {
                  let ipDnType;
                  if (ipDn.docType === 'DN') ipDnType = item.dndata.ipDnTypeDn;
                  else ipDnType = item.ipdata.ipDnType;
                  return ipDn.senderCompanyId === item.senderCompanyId && ipDn.insurancePolicyNo === item.insurancePolicyNo && ipDn.insurancePolicyBranchNo === item.insurancePolicyBranchNo && ipDn.ipDnType === ipDnType && ipDn.docType === item.docType;
                })
              ) {
                item.poolSelected = true;
              }
              return item;
            });
          }
          this.totalCount = res.maxNum;
          this.isLoading = false;
        })
        .catch(err => {
          this.items = [];
          if (err.statusCode !== 403) this.$store.dispatch('SHOW_ALERT', err.message);
          this.isLoading = false;
        });
    },
    search() {
      this.offset = 0;
      this.currentPage = 1;
      this.selectionItems = [];
      this.items = null;
      this.$store.commit('SET_QUERIES', { key: 'IP_DN_POOL_SELECT', queries: _.cloneDeep(this.searchForm) });
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      this.$store.commit('SET_QUERIES', { key: 'IP_DN_POOL_SELECT', queries: _.cloneDeep(this.searchForm) });
    },
    onPaging({ target, sortType, currentPage }) {
      if (target && sortType) {
        this.sort = {
          target: target,
          sortType: sortType
        };
        // NOTE: 一覧の区分値がないためソートターゲットを変換
        if (this.sort.target === 'update') this.sort.target = 'updateUserName';
        else if (this.sort.target === 'tradingFlowId') this.sort.target = 'tradingId';
      } else {
        this.sort = null;
      }

      this.currentPage = currentPage;
      this.offset = currentPage * this.limit - this.limit;
      this.fetch();
    },
    async rowClick(row) {
      // console.log(row);
      if (this.isLoading) return;
      this.$refs.table.toggleRowSelection(row);
    },
    // セレクト切り替え
    selectionChange(multipleSelection) {
      if (this.isLoading) return;
      this.selectionItems = multipleSelection;
    },
    async register() {
      this.registering = true;
      // 選択済みのデータは除外する
      this.selectionItems = _.reject(this.selectionItems, 'poolSelected');
      await Promise.allSettled(
        this.selectionItems.map(async (item, index) => {
          await this.attachFetch(item, index)
            .then(() => {
              return Promise.resolve();
            })
            .catch(() => {
              return Promise.reject();
            });
        })
      ).then(() => {
        this.registering = false;
        this.$emit('add-ip-dn', this.selectionItems);
      });
    },
    // 添付ファイル一覧取得BFF
    async attachFetch(row, index) {
      // BFF_GMC04 添付ファイル一覧BFF
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: 'get_/v1/gm-pool-datas/attach/search',
          query: {
            docType: row.docType,
            keyCode1: row.senderCompanyId,
            keyCode2: row.insurancePolicyNo,
            keyCode3: row.insurancePolicyBranchNo,
            keyCode4: row.docType === 'IP' ? row.trx : String(row.ipDnTypeDn),
            keyCode5: row.docType === 'IP' ? null : row.trx
          }
        }
      };
      await $api
        .request(params)
        .then(res => {
          // 添付一覧レスポンスをプロセス登録BFFリクエスト用に整形し選択中のアイテムに追加 削除済みの添付ファイルは除外
          const result = _.map(_.filter(res.attachLinks, attach => attach.deleteFlg === DELETE_FLG.NOT_DELETED), item => {
            return { attachDocumentName: item.attachFileName, attachDocumentPath: item.attachFilePath, attachLinkId: item.attachLinkId };
          });
          this.selectionItems[index].attachmentFiles = result;
          return Promise.resolve();
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          return Promise.reject();
        });
    },
    // テーブル表示時にrow-keyを一意にするためにrowにcurrentPageとindexを付与します
    setIndex(array) {
      for (let i = 0; i < array.length; i++) {
        array[i] = { ...array[i], currentPage: this.currentPage, index: i };
      }
      return array;
    },
    // テーブルのrow-keyを返却します（チェック状態保持）
    getRowKey(row) {
      // NOTE: currentPageとindexで一意にして返却
      return `${row.currentPage}_${row.index}`;
    },
    // IP, DNの情報を一階層上に展開
    flatten(ary) {
      return _.map(ary, o => {
        const targetType = o.docType === 'DN' ? 'dn' : 'ip';
        return {
          ...o,
          ipDnType: _.get(o, `${targetType}data.ipDnType`) || null,
          ipDnTypeDn: _.get(o, `${targetType}data.ipDnTypeDn`) || null,
          senderCompanyId: _.get(o, `${targetType}data.senderCompanyId`, null),
          trx: _.get(o, `${targetType}data.trx`, null),
          tradingId: _.get(o, `${targetType}data.tradingId`, null),
          tradingFlowId: _.get(o, `${targetType}data.tradingFlowId`, null),
          processName: _.get(o, `${targetType}data.processName`, null),
          deleteFlg: _.get(o, `${targetType}data.deleteFlg`, null),
          ownerCompanyName: _.get(o, `${targetType}data.ownerCompanyName`, null),
          ownerSectionName: _.get(o, `${targetType}data.ownerSectionName`, null),
          shipperReferenceNo: _.get(o, `${targetType}data.shipperReferenceNo`, null),
          invoiceNo: _.get(o, `${targetType}data.invoiceNo`, null),
          opOcProvNo: _.get(o, `${targetType}data.opOcProvNo`, null),
          insurancePolicyNo: _.get(o, `${targetType}data.insurancePolicyNo`, null),
          insurancePolicyBranchNo: _.get(o, `${targetType}data.insurancePolicyBranchNo`, null),
          vesselName: _.get(o, `${targetType}data.vesselName`, null),
          sailingOnOrAbout: _.get(o, `${targetType}data.sailingOnOrAbout`, null),
          assuredsEtc: _.get(o, `${targetType}data.assuredsEtc`, null),
          portOfLoadingName: _.get(o, `${targetType}data.portOfLoadingName`, null),
          finalDestinationName: _.get(o, `${targetType}data.finalDestinationName`, null),
          insuranceCompanyName: _.get(o, `${targetType}data.insuranceCompanyName`, null),
          agentforwarder: _.get(o, `${targetType}data.agentforwarder`, null),
          descriptionOfGoods01: _.get(o, `${targetType}data.descriptionOfGoods01`, null),
          cargoAmountInsured: _.get(o, `${targetType}data.cargoAmountInsured`, null),
          createDate: _.get(o, `${targetType}data.createDate`, null),
          updateUserName: _.get(o, `${targetType}data.updateUserName`, null),
          updateUserIcon: _.get(o, `${targetType}data.updateUserIcon`, null)
        };
      });
    }
  }
};
</script>

<style lang="scss">
.el-dialog__wrapper[aria-label='Select I/P, D/N'] .el-dialog {
  .el-dialog__body {
    padding-top: 12px;
  }

  h2 {
    margin: 0 0 24px;
    padding: 12px 0;
    border-bottom: 1px solid $color_gray_300;
    color: $color_gray_800;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  // .el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
  //   width: 220px;
  // }

  .is_loading {
    th .cell,
    td .cell {
      pointer-events: none;
    }
  }
}

.tw_button__icon.icon_search {
  padding-left: 12px;
  padding-right: 20px;
}
</style>
